.current-spots-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  position: relative;
  padding-top: 140px; /* add space for managespots */
}

@media (min-width: 768px) {
  .current-spots-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (min-width: 1024px) {
  .current-spots-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, calc(25% - 20px)));
  }
}

.current-spot-card {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.current-spot-card img {
  width: 470px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.current-spot-info {
  display: grid;
  grid-template-areas:
    'location rating'
    'price    empty';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  padding: 10px 0;
  margin: 2px;
}

.current-spot-location {
  grid-area: location;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.current-average-rating {
  grid-area: rating;
  display: flex;
  align-items: top;
  justify-content: right;
}

#star-icon {
  color: orangered;
  margin-right: 5px;
}

a {
  text-decoration: none;
  color: black;
}

.current-spot-card-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: orangered;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.current-spot-card-delete-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: orangered;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  min-width: 16%;
}

li {
  justify-content: end;
  padding-right: 5px;
}

.create-spot-button {
  /* Button styles */
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: orangered;
  background-color: whitesmoke;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  /* Margin styles */
  margin-right: 10px; /* Adds 10px margin to the right of the button */
}

.managespots-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 20px;
}
