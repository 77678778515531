.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.delete-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  box-shadow: 4px 4px 4px black;
  text-align: left;
}

h2 {
  margin-top: -10px;
  margin-left: 10px;
}

.buttonboi {
  background-color: red;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 400px;
}
.buttonboi-no {
  background-color: gray;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 400px;
  margin-bottom: 20px;
}
