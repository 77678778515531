.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  box-shadow: 4px 4px 4px black;
  text-align: center;
}

.form-container input[type='text'],
.form-container input[type='password'] {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  min-width: 90%;
  font-size: 1.1rem;
  margin-left: 17px;
  background-color: #e1f0ff;
}

.form-container p {
  color: red;
  margin-bottom: 1rem;
}

.loginbutton {
  background-color: orangered;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.disabledloginbutton {
  background-color: #ccc;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.loginbutton:hover {
  background-color: #c63700;
}

.form-container form {
  width: 80%;
  box-sizing: border-box;
}

.loginbutton-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-button {
  background-color: orangered;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 10px;
}
