.review-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.post-review-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  box-shadow: 4px 4px 4px black;
  text-align: left;
}

.review-description-input {
  border: 2px solid black;
  width: 100%;
  height: 100px;
  font-size: 16px;
  background-color: #e1f0ff;
  resize: vertical;
  overflow-y: auto;
  margin-top: -10px;
}

.submit-review-button {
  background-color: orangered;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 400px;
}

.postreviewform {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.fa-solid.fa-star {
  font-size: 1.5rem;
  color: orangered;
}

.fa-regular.fa-star {
  font-size: 1.5rem;
}

.stars-text {
  font-size: 1.5rem;
  margin-left: 10px;
}

.disabled-submit-review-button {
  background-color: gray;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 400px;
}
