.profile-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-dropdown {
  position: absolute;
  top: 70px;
  right: 8px;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  list-style: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  min-width: auto;
}

#signed-in {
  min-height: 120px;
}

#signed-out {
  min-height: 40px;
  min-width: 5%;
}

.profile-dropdown.hidden {
  display: none;
}

.buttons {
  grid-template-areas:
    'buttonone'
    'buttontwo';
}

.button1 {
  grid-area: 'buttonone';
  display: flex;
  font-size: 1.2rem;
}

.button2 {
  grid-area: 'buttontwo';
  display: flex;
  font-size: 1.2rem;
}

.button1:hover {
  cursor: pointer;
}

.button2:hover {
  cursor: pointer;
}

.profile-dropdown li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
  text-decoration: underline;
}

.profile-dropdown li button:hover {
  color: #666;
}

.loggedin-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 0;
  text-align: left;
  align-items: flex-end;
}

#icon {
  color: orangered;
}

.profile-button-user {
  background-color: whitesmoke;
  border: 2px solid black;
  border-radius: 10px;
}

.profile-button-user:hover {
  cursor: pointer;
}

#menu-icon {
  color: orangered;
  margin-right: 5px;
}
